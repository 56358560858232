/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonToggle {
    classBlock = 'button-toggle--block'
    classButton = 'button-toggle--button'

    classBlockOpen = 'header-mobile-open'
    classBodyOverflow = 'overflow-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`body`).toggleClass(self.classBodyOverflow)
            $(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
